<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import SolutionsSection from './solutions/components/SolutionsSection.vue'
import FooterCta from '~/pages/components/new/FooterCta.vue'
import BlogSection from '~/pages/components/new/landing/BlogSection.vue'
import StatsSection from '~/pages/components/new/StatsSection.vue'
import LandingPage from './components/LandingPage.vue'

definePageMeta({
    // layout: 'marketing',
    middleware: 'auth',
})

const router = useRouter()

const civiqaUser = useCiviqaUser()

if (civiqaUser.value && !civiqaUser.value.isFirebaseAnonymous) {
    router.replace('/app/boards')
}

</script>

<template>
    <LandingPage />
</template>
